import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { MetaTags } from "react-meta-tags"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import classNames from "classnames"
import { getStockInById, updateStockIn, getItemUnitOfMeasurements } from "store/actions"
import StockInItemTab from "./StockInItemTab"
import StockInForm from "../../containers/stock-in/StockInForm"

const StockInDetail = props => {
  const { updateStockIn, match, history } = props

  const [ activeTab, setActiveTab ] = useState("1")
  const [ id, setId ] = useState(null)

  const { getStockInById, stockIn } = props
  const { item, loading } = stockIn

  useEffect(() => {
    const { id } = match.params
    getStockInById(id)
    setId(id)
  }, [])

  const handleOnRefresh = () => {
    getStockInById(id)
    setId(id)
  }

  const handleOnSubmit = (data) => {
    updateStockIn(data)
    history.push(`/stock-in`)
  }

  const handleOnCancel = () => {
    history.push(`/stock-in`);
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Stock In Detail | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            { loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
            ) : (
                <>
                  { item ? (
                      <>
                        <Breadcrumbs
                            title="Home"
                            backLink={ "/stock-in" }
                            breadcrumbItem={ `${ item.code } | Item Stock In Detail` }
                        />
                        <StockInForm item={ item } onSubmit={ handleOnSubmit } onCancel={ handleOnCancel }
                                     onRefresh={ handleOnRefresh }/>
                      </>
                  ) : null }
                </>
            ) }
          </Container>
        </div>
      </React.Fragment>
  )
}

StockInDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  stockIn: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getItemUnitOfMeasurements: PropTypes.func,
  getStockInById: PropTypes.func,
  updateStockIn: PropTypes.func,
}

const mapStateToProps = ({ stockIn }) => {
  return {
    stockIn,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getStockInById,
      updateStockIn,
      getItemUnitOfMeasurements,
    })(StockInDetail)
)
